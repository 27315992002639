export const environment = {
    production: false,
    auth: {
        clientID: 'y1lQgt588ZtS4N8SmS1XFiDTCgNh1D54',
        audience: 'https://media.ra.dev',
        domain: 'media-dev.auth0.com',
        algorithm: 'RSA256',
        responseType: 'token id_token',
        scope: 'openid profile email'
    },
    api: 'https://api-phoenix-dev.media-dev.revenueanalytics.io',
    sfOpportunityUrl: 'https://pandora--pandora1.lightning.force.com/lightning/r/Opportunity/OPPORTUNITY_ID/view'
};
